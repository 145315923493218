<template>
  <!-- 联系我们 -->
  <div>
    <pc-header :active1="5"></pc-header>
    <div class="main">
      <div class="NeiBanner">
        <img
          style="width: 100%; height: 900px"
          :src="productBannerList.img_url"
          alt=""
        />
      </div>
      <div class="NeiNav">
        <div class="cmain">
          <ul>
            <!-- class="active" -->
            <li>
              <a href="##">
                <span class="span1">联系方式</span>
                <span class="span2">CONTACT WAY</span>
              </a>
            </li>
            <li>
              <a href="##">
                <span class="span1">留言板</span>
                <span class="span2">MESSAGE BOARD</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="Address">
        <div class="cmain">
          <ul>
            <li>
              <dl>
                <dt class="m1">
                  <em>地址 / ADDRESS</em>
                </dt>
                <dd>
                  <span class="span1">重庆市忠县移民生态工业园区</span>
                  <span class="span2">
                    重庆办事处:{{ companyDetail.address }}
                  </span>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt class="m2">
                  <em>电话 / TEL</em>
                </dt>
                <dd>
                  <span class="span1">{{ companyDetail.phone }}</span>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt class="m3">
                  <em>邮编 / ZIP</em>
                </dt>
                <dd>
                  <span class="span1">{{ companyDetail.number }}</span>
                </dd>
              </dl>
            </li>
            <li style="margin-right: 0">
              <dl>
                <dt class="m4">
                  <em>邮箱 / MAIL</em>
                </dt>
                <dd>
                  <span class="span1">lintom@cqnoe.com </span>
                  <span class="span2"> {{ companyDetail.email }} </span>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </div>
      <div id="Map">
        <!-- <iframe
          height="500"
          src="http://apis.map.qq.com/tools/poimarker?type=0&amp;marker=coord:30.13569,120.180764;title:%E6%9D%AD%E5%B7%9E%E6%9C%9B%E9%A3%9E%E6%99%BA%E8%83%BD%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%0A;addr:%E6%9D%AD%E5%B7%9E%E5%B8%82%E8%90%A7%E5%B1%B1%E5%8C%BA%E9%97%BB%E5%A0%B0%E8%A1%97%E9%81%93%E9%9A%90%E9%BE%99%E6%B9%BE3%E5%B9%A21%E5%8D%95%E5%85%832704%E5%AE%A4%0A&amp;key=HPVBZ-F35H6-ZEBSK-EEI6X-NRDAH-XIB3R&amp;referer=myapp"
          frameborder="0"
          width="100%"
        >
        </iframe> -->
      </div>
      <div class="ToMeForm">
        <div class="ToMeMain">
          <form>
            <div class="TMLebelMain">
              <div class="TMLebel resetx">
                <label>
                  <input
                    type="text"
                    name="TMName"
                    placeholder="标题"
                    v-model="params.title"
                  />
                </label>
              </div>
              <div class="TMLebel lf">
                <label>
                  <input
                    type="text"
                    name="TMName"
                    class="TMName"
                    placeholder="姓名"
                    v-model="params.name"
                  />
                </label>
              </div>
              <div class="TMLebel rt">
                <label>
                  <input
                    type="text"
                    name="TMTels"
                    class="TMTels"
                    placeholder="电话"
                    v-model="params.phone"
                  />
                </label>
              </div>
            </div>
            <div class="TMLebel">
              <label>
                <textarea
                  name="TMText"
                  id="TMText"
                  class="TMText"
                  placeholder="留言"
                  v-model="params.content"
                ></textarea>
              </label>
            </div>
            <button type="button" class="TMBtns" @click="addMeg">提交</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pcHeader from "@/components/pc-header";

export default {
  components: {
    pcHeader,
  },
  data() {
    return {
      productBannerList: "",
      companyDetail: {},
      params: {
        title: "",
        name: "",
        phone: "",
        content: "",
      },
    };
  },
  methods: {
    getProductBanner() {
      this.$fetch({
        url: "/api/index/productBanner",
        params: { type: 5 },
      }).then((res) => {
        this.productBannerList = res.data.data;
      });
    },

    getDetail() {
      this.$fetch({
        url: "/api/index/company",
      }).then((res) => {
        this.companyDetail = res.data.data;
      });
    },
    addMeg() {
      if (this.params.title == "") {
        this.$message.error("请输入标题!");
        return;
      }
      if (this.params.name == "") {
        this.$message.error("请输入姓名!");
        return;
      }
      if (this.params.phone == "") {
        this.$message.error("请输入电话!");
        return;
      }
      if (this.params.content == "") {
        this.$message.error("请输入留言内容!");
        return;
      }
      this.$fetch({
        url: "/api/index/addMsg",
        params: this.params,
      }).then((res) => {
        this.$message.success("提交成功");
        this.companyDetail = res.data.data;
      });
    },
  },
  created() {
    this.getProductBanner();
    this.getDetail();
  },
};
</script>